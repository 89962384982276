import { createSelector } from '@ngrx/store';

import { State } from 'src/app/modules/ngrx-store/your-home/your-home.reducer';
import { getAppDomainState } from 'src/app/modules/ngrx-store/selectors';

export const getHomeDetails = createSelector(
	getAppDomainState,
	(state: State) => state.homeDetails
);

export const getHomeDetailsFailure = createSelector(
	getAppDomainState,
	(state: State) => state.loadHomeDetailsFailure
);

export const getHomeDetailsPending = createSelector(
	getAppDomainState,
	(state: State) => state.loadHomeDetailsPending
);

export const getHomeDetailsSuccess = createSelector(
	getAppDomainState,
	(state: State) => state.loadHomeDetailsSuccess
);

export const getJobOptions = createSelector(
	getAppDomainState,
	(state: State) => state.jobOptions
);

export const getJobOptionsPending = createSelector(
	getAppDomainState,
	(state: State) => state.loadJobOptionsPending
);

export const getSalesAgreementId = createSelector(
	getAppDomainState,
	(state: State) => state.homeDetails?.salesAgreementId
);

export const getIsPhdLite = createSelector(
	getAppDomainState,
	(state: State) => (state.jobOptions?.jobChoices?.length === 0 && !state.homeDetails?.salesAgreementNumber?.startsWith('HB'))
);

export const getIsHomeSelections = createSelector(
	getAppDomainState,
	(state: State) => (state.jobOptions?.jobChoices?.length === 0 && state.homeDetails?.salesAgreementNumber?.startsWith('HB'))
);

export const getPlanSalesName = createSelector(
	getAppDomainState,
	(state: State) => state.homeDetails?.planCommunity?.planSalesName
);

export const getFinancialPlanIntegrationKey = createSelector(
	getAppDomainState,
	(state: State) => state.homeDetails?.planCommunity?.financialPlanIntegrationKey
);

export const getFloorPlanAttachments = createSelector(
	getAppDomainState,
	(state: State) => state.jobOptions?.floorPlanAttachments
);

export const getIsDesignPreviewEnabled = createSelector(
	getAppDomainState,
	(state: State) => state.homeDetails?.financialCommunity.isDesignPreviewEnabled
);

export const getCloseOfEscrow = createSelector(
	getAppDomainState,
	(state: State) => state.homeDetails?.lot.closeOfEscrow
);

export const getLotId = createSelector(
	getAppDomainState,
	(state: State) => state.homeDetails?.lot.id
);