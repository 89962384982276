import { ActionCreator, ReducerTypes, on } from '@ngrx/store';

import * as ConstructionProgressActions from 'src/app/modules/ngrx-store/construction-progress/construction-progress.actions';

import { ConstructionUpdate } from 'src/app/modules/core/models/shr.model';

export interface State {
	/*
	 * constructionUpdates
	 */
	constructionUpdates?: ConstructionUpdate[] | null,
	loadConstructionUpdatesFailure?: Error | null,
	loadConstructionUpdatesPending?: boolean,
	loadConstructionUpdatesSuccess?: boolean,
}

export const initialState: State = {}

export function getConstructionProgressOns<T extends State>(): ReducerTypes<T, ActionCreator[]>[] {
	return [
		/*
		 * loadConstructionUpdates
		 */
		on(ConstructionProgressActions.loadConstructionUpdates, (state) => ({
			...state,
			loadConstructionUpdatesFailure: null,
			loadConstructionUpdatesPending: true,
			loadConstructionUpdatesSuccess: false,
		})),
		on(ConstructionProgressActions.loadConstructionUpdatesFailure, (state, { error }) => ({
			...state,
			loadConstructionUpdatesFailure: error,
			loadConstructionUpdatesPending: false,
			loadConstructionUpdatesSuccess: false,
		})),
		on(ConstructionProgressActions.loadConstructionUpdatesSuccess, (state, { constructionUpdates }) => ({
			...state,
			constructionUpdates: constructionUpdates,
			loadConstructionUpdatesFailure: null,
			loadConstructionUpdatesPending: false,
			loadConstructionUpdatesSuccess: true
		})),
		on(ConstructionProgressActions.resetConstructionUpdates, (state) => ({
			...state,
			constructionUpdates: null,
			loadConstructionUpdatesFailure: null,
			loadConstructionUpdatesPending: false,
			loadConstructionUpdatesSuccess: false
		})),
	];
}