import { ActionCreator, ReducerTypes, on } from '@ngrx/store';

import * as ServiceRequestsActions from 'src/app/modules/ngrx-store/service-requests/service-requests.actions';

import { ServiceRequestResponse } from 'src/app/modules/core/models/svc.model';

export interface State {
	/*
	 * serviceRequests
	 */
	serviceRequests?: ServiceRequestResponse[] | null,
	loadServiceRequestsFailure?: Error | null,
	loadServiceRequestsPending?: boolean,
	loadServiceRequestsSuccess?: boolean,
	/*
	 * postServiceRequest
	 */
	postServiceRequestFailure?: Error | null,
	postServiceRequestPending?: boolean,
	postServiceRequestSuccess?: boolean
}

export const initialState: State = {};

export function getServiceRequestsOns<T extends State>(): ReducerTypes<T, ActionCreator[]>[] {
	return [
		/*
		 * loadServiceRequests
		 */
		on(ServiceRequestsActions.loadServiceRequests, (state) => ({
			...state,
			loadServiceRequestsFailure: null,
			loadServiceRequestsPending: true,
			loadServiceRequestsSuccess: false,
		})),
		on(ServiceRequestsActions.loadServiceRequestsSuccess, (state, { serviceRequests }) => ({
			...state,
			serviceRequests: serviceRequests,
			loadServiceRequestsFailure: null,
			loadServiceRequestsPending: false,
			loadServiceRequestsSuccess: true
		})),
		on(ServiceRequestsActions.loadServiceRequestsFailure, (state, { error }) => ({
			...state,
			loadServiceRequestsFailure: error,
			loadServiceRequestsPending: false,
			loadServiceRequestsSuccess: false
		})),
		on(ServiceRequestsActions.resetServiceRequests, (state) => ({
			...state,
			serviceRequests: null,
			loadServiceRequestsFailure: null,
			loadServiceRequestsPending: false,
			loadServiceRequestsSuccess: false
		})),
		/*
		 * postServiceRequest
		 */
		on(ServiceRequestsActions.postServiceRequest, (state) => ({
			...state,
			postServiceRequestPending: true,
			postServiceRequestFailure: null,
			postServiceRequestSuccess: false
		})),
		on(ServiceRequestsActions.postServiceRequestSuccess, (state, { serviceRequest }) => ({
			...state,
			serviceRequests: [serviceRequest, ...state.serviceRequests || []], // Append the new service request to the front of the list, since it's sorted by date already
			postServiceRequestPending: false,
			postServiceRequestFailure: null,
			postServiceRequestSuccess: true
		})),
		on(ServiceRequestsActions.postServiceRequestFailure, (state, { error }) => ({
			...state,
			postServiceRequestPending: false,
			postServiceRequestFailure: error,
			postServiceRequestSuccess: false
		}))
	];
}